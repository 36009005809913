<template>
    <div class="card-block">
      <h5 class="card-title">Gateway Timings</h5>
      <div class="timing-item">
        <div class="timing-field" v-for="attr in timingAttrs" :key="attr.attribute_info_id">
          <small class="d-block text-muted">{{attr.attribute_pretty_name}}</small>
          <input type="number" step="1" min="1"
                 class="form-control text-center" :class="attr.status"
                 :value="attr.attribute_value"
                 @change="updateTiming($event.target.value, attr.attribute_info_id)"
          />
        </div>
      </div>
    </div>
</template>

<script>
import { useStore } from "vuex";
import {computed} from "vue";

export default {
  name: "GatewayTimingTable",
  setup() {
    const store = useStore();

    const updateTiming = (value, attr_info_id) => {
      switch (attr_info_id) {
        case 9148:
          store.commit('readingControl/setHiFreq', value);
          break;
        case 9149:
          store.commit('readingControl/setCommFreq', value);
          break;
        case 9167:
          store.commit('readingControl/setBootup', value);
          break;
        default:
          console.error('Unknown attribute info id in gateway timings.')
      }
    };

    return {
      timingAttrs: computed(() => Object.values(store.state.readingControl.timings).filter(attr => attr !== null)),
      updateTiming,
    }
  },
}
</script>

<style scoped>
.timing-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: var(--color-bg);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.5s ease, color 0.5s ease;
  overflow-x: auto;
}

.timing-field {
  flex: 1;
  margin: 0 8px;
  transition: background 0.5s ease, color 0.5s ease;
}

.timing-field >select, .timing-field >input {
  background: var(--color-bg-sec);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  color: var(--color-txt);
  max-width: 300px;
  min-width: 50px;
}

small {
  margin: 0!important;
  padding: 0!important;
  font-size: 0.75em;
  color: var(--color-txt);
}

.text-muted {
  color: var(--color-txt)!important;
}

.updated {
  border: 3px solid orange!important;
}
</style>