import axios from "axios";

export const register  = {
  namespaced: true,

  state: {
    address: null,
    address2: null,
    registerToRead: null,
    readings: [],
    attributes: [],
  },

  actions: {
    async fetchAllReadings({ rootState, state, commit }) {
      try {
        this.commit('settings/setIsLoading', true);
        let sensorId = rootState.sensor.id;
        let url = `${rootState.settings.api_base}register/readings/${sensorId}/${state.address}`;

        // Get the chart options
        let rangeType = rootState.chart.filters.dateRangeType.group_gateway_sensor;
        let startDate = rootState.chart.filters.dateRange.fromDate.group_gateway_sensor;
        let endDate = rootState.chart.filters.dateRange.toDate.group_gateway_sensor;
        let chartType = rootState.chart.type.group_gateway_sensor;

        let rs = await axios.get(url,  {
          params: {
            rangeType: rangeType,
            startDate: startDate,
            endDate: endDate,
            chartType: chartType,
          }
        });
        await commit('setReadings', rs.data);
        this.commit('settings/setIsLoading', false);
      } catch (e) {
        console.error('Failed to fetch register readings', e);
      }
    },

    async getRegisterInfo({ rootState, state, commit }) {
      try {
        let sensorId = rootState.sensor.id;
        let url = `${rootState.settings.api_base}register/info/${sensorId}/${state.address}`;
        let rs = await axios.get(url);

        if (rs.status === 200) {
          commit('setAttributes', rs.data);
        } else {
          console.error('Failed to get register info', rs.status, rs.data);
        }
      } catch (e) {
        console.error('Failed to get register info', e);
      }
    },

    async loadRegister({ commit, dispatch }, address) {
      this.commit('settings/setIsLoading', true);
      if (address != null) {
        await commit('setAddress', address);
      }
      await dispatch('getRegisterInfo');
      await dispatch('fetchAllReadings');
      await this.dispatch('AI/getPredictions');
      this.commit('settings/setIsLoading', false);
    },
  },

  mutations: {
    setAddress(state, address) {
      state.address = address;
    },

    setRegisterToRead(state, {address, upsert=true}) {
      state.registerToRead = address;
      if (!upsert) return;
      let sensorId = this.getters['sensor/id'];
      this.dispatch('user/upsertAttribute', {
        attribute_value: address,
        attribute_info_id: 9146,
        owner_id: sensorId,
      });
    },

    setReadings(state, readings) {
      state.readings = readings;
      this.commit('chart/clearData');
      this.commit('chart/setChartData', {
        listType: 'group_gateway_sensor',
        data: state.readings,
        labelField: 'receive_datetime',
        valueField: 'sensor_data_value',
        valueField2: 'sensor_data_value2',
      });
    },

    setAttributes(state, attributes) {
      state.attributes = attributes;
      this.commit('apgList/setList', {listType: 'register_attribute', data: attributes});
    }
  },

  getters: {
    address: state => state.address,
    registerToRead: state => state.registerToRead,
    attributes: state => state.attributes,
    readings: state => state.readings,
  }
}
