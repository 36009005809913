<template>
  <div class="card" style="margin-top: 20px">
    <div class="card-header">
      <span class="pe-4 fw-bold">Gateway Attribute List</span>
    </div>
    <div class="card-block">
      <ul class="list-group">
        <GatewayAttributeItem
            v-for="(item, index) in filteredAttrs"
            :value="item.attribute_value"
            :disabled="item.disabled"
            :key="index"
            :keyName="item.attribute_key"
            :idx="index"
            :type="item.attribute_type"
            :attId="item.attribute_id"
            :attInfoId="item.attribute_info_id"
            :ownerId="item.owner_id"
        />
      </ul>
      <div class="d-flex align-items-center justify-content-center noItemsSpan" v-if="!gatewayAttributeList">
        No items found for selection
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import {computed} from "vue";
import GatewayAttributeItem from "./gateway-attribute-item.vue";
export default {
  name: "gatewayAttributeList",
  components: {
    GatewayAttributeItem,
  },
  props: {
    source: String,
    usage:String,
  },
  setup() {
    const store = useStore();
    const gatewayAttributeList = computed(() => store.state.apgList.List.gateway_attribute);
    const gatewayType = computed(() => store.state.gateway.type);

    // attr ids that are ONLY for ether and cell (shared attr ids do not belong in this list)
    const etherAttrIds = [9204, 9203, 9202, 9201, 9196];
    const cellAttrIds = [];
    for (let i = 9173; i < 9194; i++) {
      cellAttrIds.push(i);
    }

    return {
      filteredAttrs: computed(() => {
        let attrs = gatewayAttributeList.value;
        if (attrs) {
          // Filter out the disabled attributes and gateway timing attributes
          attrs = attrs.filter(a => !a.disabled && ![9167, 9149, 9148, 9156].includes(a.attribute_info_id));
          // exclude attrs based on gateway type
          switch (gatewayType?.value?.gateway_type_name.toLowerCase()) {
            case 'swarm':
              attrs = attrs.filter(a => !etherAttrIds.includes(a.attribute_info_id) && !cellAttrIds.includes(a.attribute_info_id));
              break;
            case 'str_cell':
              attrs = attrs.filter(a => !etherAttrIds.includes(a.attribute_info_id));
              break;
            case 'str_ether':
              break;
            default:
              break;
          }
        }
        return attrs;
      }),
      gatewayAttributeList,
    };
  },
};
</script>
<style scoped>
.noItemsSpan {
  height: 200px;
  width: 100%;
  background-color: aliceblue;
}

.scrollable {
  height:400px;
  overflow: auto;
}
</style>