import { createStore } from "vuex"
import { auth } from "./auth.module"
import { group } from "./groups.module"
import { status_bar } from "./status_bar.module"
import { settings } from './settings.module'
import { user } from './users.module'
import { apgList } from './apgList.module'
import { gateway } from './gateways.module'
import { MyView } from './MyView.module'
import { sensor } from './sensors.module'
import { attributes } from './Attributes.module'
import { chart } from './chart.module'
import { alarm } from './alarm.module'
import { audit } from './audit.module'
import { register } from './register.module'
import { AI } from './AI.module'
import { readingControl } from './readingControl.module'

const store = createStore({
  modules: {
    auth,
    group,
    status_bar,
		settings,
		user,
    apgList,
    gateway,
    MyView,
    sensor,
    register,
    attributes,
    chart,
    alarm,
    audit,
    AI,
    readingControl,
  },
});

export default store;
