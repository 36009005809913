<template>
  <div class="card" style="margin-top: 20px">
    <div class="card-header align-content-between">
      <h5 class="card-title">Gateway Reading Control</h5>
      <button @click="confirmUpdateGRC"
              class="btn btn-outline-primary"
              style="float: right"
              id="updateQueueBtn"
              :disabled="!showSave"
      >
        Save & Queue
      </button>
      <button @click="addNewGCR"
              class="btn btn-outline-primary"
              style="float: right"
              title="Add new Gateway Reading Control"
      >
        <font-awesome-icon icon="plus" style="font-size: 11pt" />
      </button>
    </div>
    <div class="card-block">
      <div class="grc-list">
        <GatewayReadingControlItem
          v-for="gc in gatewayControlList"
          :id="gc.control_id"
          :sensor_id="gc.sensor_id"
          :sensor_register ="gc.sensor_register"
          :length ="parseInt(gc.length)"
          :power_line ="gc.power_line"
          :_function ="gc.application_type"
          :key="gc.control_id"
        />
      </div>
    </div>
    <gateway-timing-table/>
  </div>
</template>

<script>
  import { useStore } from "vuex";
  import {computed} from "vue";
  import GatewayReadingControlItem from "./gatewayControlReading-item";
  import GatewayTimingTable from "@/components/shared/lists/gatewayTimingTable.vue";
  import {onBeforeRouteLeave} from "vue-router";
  export default {
    name: "gatewaycontrolreading",
    components: {
      GatewayReadingControlItem,
      GatewayTimingTable,
    },
    props: {
      source: String,
      usage: String,
      showPlus: Boolean,
      showEraser: Boolean,
      canClick: Boolean,
      showRemoveFromUser: Boolean,
      showRemoveFromGateway: Boolean,
      status : String,
    },
    setup() {
      const store = useStore();
      const confirmUpdateGRC = async () => {
        await store.dispatch("readingControl/sendGatewayTimings");
        await store.dispatch("readingControl/SendGatewayReadingControl");
      };
      const addNewGCR = () => store.commit("readingControl/addNewGCR");

      onBeforeRouteLeave(() => {
        let gcr = store.state.apgList.List.gateway_control_reading;
        let timings = store.state.apgList.List.gateway_timings;

        // if any unsaved changes in the gateway controls or gateway timings
        if ((gcr && gcr?.find((item) => item.status === "new" || item.status === "updated")) ||
            (timings && timings?.find((item) => item.status === "changed"))) {
          const answer = window.confirm('Do you really want to leave? You have unsaved changes in the Gateway Control tab.');
          if (!answer) return false;
        }
      });

      return {
        gatewayControlList: computed(() => store.state.readingControl.controls),
        addNewGCR,
        confirmUpdateGRC,
        showSave: computed(() => store.getters['readingControl/areControlsChanged'] || store.getters['readingControl/areTimingsChanged']),
      };
    },
  };
</script>
<style scoped>
  .noItemsSpan {
    height: 200px;
    width: 100%;
    background-color: aliceblue;
  }

  .card-block {
    padding: 16px; /* Add padding around the block */
  }

  .grc-list {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 16px; /* Add space between each alarm item */
    width: 100%; /* Ensure it occupies the full width of its parent */
  }
</style>