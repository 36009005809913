import moment from 'moment'
import axios from "axios";
import {useToast} from "vue-toastification";
const toast = useToast();

const chartSlots = {
  group_gateway_sensor: {},
}

export const chart = {
  namespaced: true,
  state: {
    type: {
      group_gateway_sensor: 'line',
    },
    data: {
      ...chartSlots,
    },
    rawData: {
      ...chartSlots,
    },
    filters: {
      dateRangeType: {
        ...chartSlots
      },
      dateRange: {
        fromDate: { ...chartSlots },
        toDate: { ...chartSlots },
      },
      showingTimeouts: false,
      showingAlarms: false,
    },
    visible: {
      ...chartSlots
    },
    unresponsive_data: {
      ...chartSlots
    },
    has_unresponsive_data: {
      'group_gateway_sensor': false
    },
    chart_modals: {
      unresponsive_data_modal: false,
    },
    showing_predictions: false,
    register_to_read: false,
    url_chart_outlier: 'outliers/',
  },
  mutations: {
    setDateRangeType(state, payload) {
      state.filters.dateRangeType[payload.listType] = payload.dateRangeType
    },
    setChartData(state, payload) {
      state.rawData[payload.listType] = payload.data

      const convertToLocal = timestamp => moment.utc(timestamp).local().toDate();
      const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n) && n !== null && n !== 'null';

      let d3Data = [];
      payload.data.forEach(function(item) {
        d3Data.push({
          x: convertToLocal(item[payload.labelField]),
          y: parseFloat(item[payload.valueField]),
          z: parseFloat(item[payload.valueField2])
        });
      });

      state.data[payload.listType] = d3Data.filter(item => isNumeric(item.y));
      state.unresponsive_data[payload.listType] = d3Data.filter(item => !isNumeric(item.y));

      // set the time range for the chart
      if (state.filters.dateRangeType?.[payload.listType]?.endsWith('r')) {
        let allDates = d3Data.map(item => moment(item.x));
        let first = moment.min(allDates);
        let last = moment.max(allDates);
        this.commit('chart/setDate', {which: 'fromDate', listType: payload.listType, date: first.toISOString()});
        this.commit('chart/setDate', {which: 'toDate', listType: payload.listType, date: last.toISOString()});
      }

      // set the state bools
      state.has_unresponsive_data[payload.listType] = state.unresponsive_data[payload.listType].length > 0;
    },

    setChartPredictionData(state, payload) {
      // add raw predicted data (just in case we need it later)
      state.rawData['ai_predictions'] = payload;
      // D3 format
      let aiData = [];
      for (let i = 0; i < payload.prediction.length; i++) {
        aiData.push({
          x: moment.utc(payload.timestamps[i]).local().toDate(),
          y: payload.prediction[i],
          z: payload.prediction2?.[i]
        });
      }
      // add the prediction to the current dataset
      state.data[payload.listType] = aiData;
    },
    setDate(state, payload) {
      state.filters.dateRange[payload.which][payload.listType] = payload.date
    },
    showChartModal(state, payload) {
      state.chart_modals.unresponsive_data_modal = payload.show_modal
    },
    clearData(state) {
      state.data['group_gateway_sensor'] = [];
      state.data['ai_predictions'] = [];
      state.showing_predictions = false;
    },
    setShowingPredictions(state, payload) {
      state.showing_predictions = payload;
    },
    setShowingTimeouts(state, payload) {
      state.filters.showingTimeouts = payload;
    },
    setShowingAlarms(state, payload) {
      state.filters.showingAlarms = payload;
    },
    async setChartType(state, payload) {
      state.type[payload.listType] = payload.chartType;
      if (payload.chartType === 'wind') {
        await this.dispatch('register/fetchAllReadings');
      }
    },
  },
  getters: {

  },

  actions: {
    async removeOutliers({rootState, state, commit}, payload) {
      // get the current chart data
      let data = state.data[payload.listType];

      // filter out the outliers
      let url = rootState.settings.api_base + state.url_chart_outlier + payload.id +'/'+ payload.address;
      console.log('outlier url: ', url);
      let rs = await axios.post(url, { data: data });
      console.log('outlier response', rs);

      // if the response is good, update the chart data
      if (rs?.data?.status === 200) {
        commit('setChartData', {
          listType: payload.listType,
          data: rs.data.data,
          labelField: 'x',
          valueField: 'y',
          valueField2: 'z',
        });
        if (rootState.chart.showing_predictions) {
          let aiData = state.rawData['ai_predictions'];
          commit('setChartPredictionData', aiData);
        }
        toast.success('Outliers removed');
      } else {
        toast.error('Failed to filter outlier data');
        console.error('Outlier error:', rs?.data?.error?.message);
      }
    },
  }
}
