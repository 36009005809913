<template>
  <div class="card overflow-visible" style="margin-top: 10px; height: 80vh; display: flex; flex-direction: column;">
    <div class="card-header">Pending Messages to be Sent
      <button @click="sendMessageToGateway" class="btn btn-outline-primary" style="float: right">
        Send Pending Messages
        <font-awesome-icon icon="satellite" style="font-size: 11pt"/>
      </button>
      <button v-if="isAdmin()" @click="createRawMessage" class="btn btn-outline-primary" style="float: right">
        Add Raw Message
        <font-awesome-icon icon="plus" style="font-size: 11pt"/>
      </button>
    </div>
    <div class="card-block table-responsive">
      <table class="table table-bordered">
        <tr class="sticky-top">
          <th>Raw Message</th>
          <th>Message Description</th>
          <th>Last Update</th>
          <th>User</th>
          <th>Remove</th>
        </tr>
        <tr v-for="(pending, idx) in messageQueue" :key="pending">
          <td v-if="pending.command_pretty_name != 'User Created Raw Message'" style="text-align: center">
            {{ pending.data_to_send }}
          </td>
          <td v-else style="text-align: center">
            <Form>
              <Field @change="saveRawMessage($event.target.value, idx)" name="hex" :rules="hexRules"
                     class="form-control-sm" type="text" placeholder="Raw Hex Message" v-model="pending.data_to_send"
                     style="width:100%; text-align: center"/>
              <ErrorMessage class="error-feedback" name="hex"/>
            </Form>
          </td>
          <td style="text-align: center">{{ pending.command_pretty_name?.replace('||', '<BR/>') }}</td>
          <td style="text-align: center">{{ moment(pending.last_update_ts) }}</td>
          <td style="text-align: center">{{ pending.username }}</td>
          <td style="text-align: center">
            <button class="form-control" @click="removeMessage(pending.send_data_queue_id, pending.gateway_id)">X
            </button>
          </td>
        </tr>
      </table>
    </div>

    <div class="card-header">Previously Sent Messages</div>
    <div class="card-block table-responsive" style="flex: 1; overflow-y: auto;">
      <table class="table table-bordered">
        <tr class="sticky-top-z1">
          <th>Commands Sent</th>
          <th>Sent</th>
          <th>Received</th>
          <th>Retries</th>

        </tr>
        <tr v-for="(sent) in messageSent" :key="sent">
          <td>
            <Multiselect class="w-auto text-center"
                         :options="sent.combined_pretty_name?.split('|') || []"
                         :placeholder="(sent.combined_pretty_name?.split('|')?.length || 0) + ' commands sent'"
                         :close-on-select="true"
                         :clear-on-select="false"
                         :searchable="false"
                         open-direction="below"
                         :show-labels="false"
                         :show-pointer="false"
            />
          </td>
          <td style="text-align: center">{{ moment(sent.date_sent) }}</td>
          <td style="text-align: center">{{ moment(sent.date_received) }}</td>
          <td style="text-align: center">
            {{ sent.retries}}
            <i class="bi bi-stop-circle-fill "
               v-if="sent.date_received == null && sent.retries < 6"
               @click="setRetries(sent.message_id, 6)"
               style="cursor: pointer; color: red; font-size: 1.5em; margin-left: 5px;"
               title="Stop Retrying"
            />
          </td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
import {computed} from 'vue'
import {useToast} from 'vue-toastification';
import {useStore} from 'vuex'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import {Form, Field, ErrorMessage} from "vee-validate";
import * as yup from "yup";

export default {
  components: {Multiselect, Form, Field, ErrorMessage},
  methods: {
    moment: function (date) {
      if (date != null) {
        return moment(date).format('MMM Do YY, h:mm:ss a');
      } else {
        return '';
      }
    }
  },
  setup() {
    let store = useStore();
    const toast = useToast();

    const sendMessageToGateway = function () {
      let pendingMessages = store.state.apgList.List['gateway_queued_message'];
      for (let msg of pendingMessages) {
        if (!msg.data_to_send || msg.data_to_send.length === 0) {
          toast.error('Raw message cannot be empty');
          return;
        }
      }
      store.dispatch("gateway/sendMessage", {id: store.state.apgList.ids.gateway.gateway});
    }

    const removeMessage = async function (send_data_queue_id, gateway_id) {
      if (confirm('You are about to remove a pending message to the gateway. Please confirm')) {
        if (send_data_queue_id) {
          await store.dispatch("gateway/removeMessage", {send_data_queue_id: send_data_queue_id, id: gateway_id});
        } else {
          await store.dispatch('gateway/findQueuedMessages', {id: gateway_id});
        }
      }
    }

    const isAdmin = () => {
      return store.state.auth.user?.user?.Info?.user_type === '1'
          || store.state.auth.user?.user?.Info?.myViewRawMessageView == 'true';
    }

    const createRawMessage = () => {
      let username = store.state.auth.user?.user?.Info?.last_name + ' ' + store.state.auth.user?.user?.Info?.first_name;
      store.dispatch('gateway/createRawMessage', {
        gateway_id: store.state.apgList.ids?.gateway?.gateway,
        user_id: store.state.auth.user?.user?.Auth?.system_user_id,
        ts: moment.now(),
        username: username,
      });
    }

    const saveRawMessage = (hexMsg, idx) => {
      let messageRow = store.state.apgList.List.gateway_queued_message?.[idx];
      console.log('msg row:', messageRow);
      if (!hexMsg || hexMsg.length === 0) {
        toast.error('Raw message cannot be empty');
        return;
      }
      messageRow.data_to_send = hexMsg;
      store.dispatch('gateway/saveRawMessage', {...messageRow});
    }

    const hexRules = yup.string().matches(/^(?:0[xX])?[0-9a-fA-F]+$/, 'Invalid hexadecimal!').required();

    return {
      removeMessage,
      sendMessageToGateway,
      isAdmin,
      createRawMessage,
      saveRawMessage,
      hexRules,
      messageQueue: computed(() => store.state.apgList.List['gateway_queued_message']),
      messageSent: computed(() => {
        let sentMsgs = store.state.apgList.List.gateway_sent_message;
        if (sentMsgs) {
          // filter out the stale message that have been resent
          return sentMsgs.filter(i => moment(i.date_received).unix() > 30000 || i.date_received == null);
        } else {
          return sentMsgs;
        }
      }),
      setRetries: (msgId, retries) => {
        store.dispatch('gateway/setSentRetries', {
          messageId: msgId,
          retries: retries
        });
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
